import { API_URL, NOIMAGE } from "../config/index"
import { Link, useLoaderData, useNavigate} from "react-router-dom"
import ProductsList from "../components/ProductsList"
import { useContext, useEffect, useState } from "react"
import { 
  idToString,
  localStorageService,
  ucFirst,
} from "../utils/index"
import Links from "../components/Links"
import CatalogsList from "../components/CatalogsList"
import { FaBorderAll, FaList, FaPencilAlt, FaTimes } from "react-icons/fa"
import { FaRegFolderOpen, FaFolderClosed } from "react-icons/fa6"
import { useFilteredCatalogs, useQntByCatalogId } from "../swr"

import { ToastContainer, toast } from "react-toastify"
import HandleConfirm from "../components/HandleConfirm"
import AuthContext from "../context/AuthContext"
import { useSWRConfig } from "swr"

import BrandContext from "../context/BrandContext"
import { Helmet } from "react-helmet-async"


export default function Catalog() { 
  const catalog = useLoaderData()

  const [isShowAsList, setIsShowAsList] = useState(() => {
    const data = localStorageService.get("catalog")
    const state =
      data && data.hasOwnProperty("isShowAsList") ? data.isShowAsList : false
    return state
  })
  const { brand } = useContext(BrandContext)

  const { mutate } = useSWRConfig()
  const navigate = useNavigate()
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  // Флаг isShowProducts выставляется когда нужно показать товары всех детей каталога
  const [isShowProducts, setIsShowProducts] = useState(false)

  const qntProducts = useQntByCatalogId({
    id: catalog._id,
    brandId: brand._id ? brand._id : "",
  })

  const catalogs = useFilteredCatalogs(brand._id)

  const childrenList = catalogs
    ? catalogs.filter(
        (item) => idToString(item.parentId) === idToString(catalog._id)
      )
    : []

 

  useEffect(() => {
    localStorageService.update("catalog", { isShowAsList })
  }, [isShowAsList])

  const handleDelete = async (rez) => {
    if (rez) {
      if (childrenList.length) {
        toast.warning("Обнаружены подкаталоги. Сначала удалите их")
        return
      }
      const res = await fetch(`/api/catalogs/${catalog._id}`, {
        method: "DELETE",
      })
      const data = await res.json()
      if (!res.ok) {
        toast.error(data.message)
        return
      }
      mutate(`/api/catalogs/brand/${brand._id}`)
      navigate(-1)
    }
    setShowDeleteModal(false)
  }

  return (
    <>
      <ToastContainer />

      <Helmet>
        <title>
          {`${ucFirst(catalog.crumbs.map((item) => item.name).join(" "))} - Кармен | Купить оптом, опт, продажа. Низкие ценв, доставка по Украине `}
        </title>
        <meta
          name="description"
          content={`Купить оптом, мелким оптом  ${catalog.crumbs
            .map((item) => item.name)
            .join(" ")
            .toLowerCase()} в магазине Кармен. Доставка с рынка Барабашово или Новой Почтой`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://www.schema.org",
            "@type": "catalog",
            name: ucFirst(catalog.crumbs.map((item) => item.name).join(" ")),
            image: `${API_URL}${catalog.image.md}`,
          })}
        </script>
      </Helmet>

      <Links home={true} back={true}>
        <div className="flex   justify-between">
          <div className="flex items-center gap-x-4">
            <div className=" flex flex-wrap text-sb">
              {catalog && catalog.crumbs.map((item, i, arr) => (
                    <div className="flex text-base text-sb" key={i}>
                      <Link to={`/catalog/${item.slug}/${item._id}`}>
                        <p className=" cursor-pointer hover:text-blue-800">
                          {item.name}
                        </p>
                      </Link>
                      {i < arr.length - 1 ? (
                        <div className="px-1">➔</div>
                      ) : null}
                    </div>
                  ))
                }
            </div>
            {isAdmin && !!catalog && (
              <div className="hidden md:flex items-center gap-x-3">
                <FaPencilAlt
                  className="text-sb cursor-pointer text-xl"
                  onClick={() => navigate(`/edit_catalog/${catalog._id}`)}
                />
                <FaTimes
                  className="text-red-500 text-2xl cursor-pointer"
                  onClick={() => setShowDeleteModal(true)}
                />
              </div>
            )}
          </div>
          {(isShowProducts || !childrenList.length) && (
            <div
              className="flex justify-center items-center  gap-x-2 px-1
           text-2xl text-green-700"
            >
              <div
                className="cursor-pointer"
                title="Список"
                onClick={() => setIsShowAsList(true)}
              >
                <FaList />
              </div>
              <div
                className="cursor-pointer"
                title="Плитка"
                onClick={() => setIsShowAsList(false)}
              >
                <FaBorderAll />
              </div>
            </div>
          )}
        </div>
      </Links>

      <div className="flex flex-col gap-3 flex-1 pt-4">
        <div
          className="flex justify-center md:justify-start gap-4 h-[70px] items-center
              px-3 py-1 bg-neutral-100"
        >
          <img
            className="max-w-[100px] max-h-[66px]"
            src={catalog.image.sm ? `${catalog.image.sm}` : `${NOIMAGE}`}
            alt={catalog ? catalog.name : ""}
          />
          <div className="">
            <h1 className="text-2xl lg:text-3xl text-amber-900 inline">
              {catalog.name}{" "}
            </h1>
            <span className="text-gray-600 text-xl">{`(${qntProducts} шт)`}</span>
          </div>

          <div className="flex-1 flex justify-end relative  text-4xl text-amber-900 ">
            {isShowProducts ? (
              <FaRegFolderOpen
                className="cursor-pointer"
                title="Скрыть тоывры этого каталога"
                onClick={() => setIsShowProducts((prev) => !prev)}
              />
            ) : (
              <FaFolderClosed
                className="cursor-pointer"
                title="Показать все товары этого каталога"
                onClick={() => setIsShowProducts((prev) => !prev)}
              />
            )}
          </div>
        </div>
        <div className="flex-1">
          {!isShowProducts && childrenList.length ? (
            <CatalogsList catalogs={childrenList} />
          ) : (
            <ProductsList
              isShowAsList={isShowAsList}
              id={catalog._id}
              isShowProducts={isShowProducts}
              brand={brand}
            />
          )}
        </div>
        {showDeleteModal && (
          <HandleConfirm
            title1="Удалить каталог"
            title2={`${catalog.name}?`}
            handle={handleDelete}
          />
        )}
      </div>
    </>
  )
}
