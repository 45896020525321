import React, { useEffect, useRef, useState } from "react"
import Loupe from "./Loupe"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"

export default function GallerySlider({ products, card: Card, title }) {
  const content = useRef()
  const [image, setImage] = useState("")
  const [isShow, setIsShow] = useState(false)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [maxScroll, setMaxScroll] = useState()

  // навешиваем обработчик на изменение scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrollLeft(content.current.scrollLeft)
    }
    const elemContent=content.current
    elemContent.addEventListener("scroll", handleScroll)
    return () => {
      if (elemContent) {
        elemContent.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  // навешиваем обработчик на изменение размера окна resize
  useEffect(() => {
  
    const handleResize = () => {
      setMaxScroll(content.current.scrollWidth - content.current.clientWidth)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
// пересчитываем maxScroll при изменении products (при редактировании)
  useEffect(() => {
      setMaxScroll(content.current.scrollWidth - content.current.clientWidth)
  },[products])

  const handleLeft = () => {
    const minScroll = 0
    const scrollingStep = content.current.clientWidth * 0.6

    const scrollTarget =
      content.current.scrollLeft - scrollingStep > minScroll
        ? content.current.scrollLeft - scrollingStep
        : minScroll

    const step = () => {
      content.current.scrollLeft -= 30

      if (content.current.scrollLeft > scrollTarget) {
        requestAnimationFrame(step)
      }
    }

    step()
  }
  const handleRight = () => {
    const scrollingStep = content.current.clientWidth * 0.6

    const scrollTarget =
      content.current.scrollLeft + scrollingStep < maxScroll
        ? content.current.scrollLeft + scrollingStep
        : maxScroll

    const step = () => {
      content.current.scrollLeft += 30

      if (content.current.scrollLeft < scrollTarget) {
        requestAnimationFrame(step)
      }
    }

    step()
  }

  return (
    <>
      <div className="min-h=[400px]  border border-blue-500 rounded-md px-2 mx-1 my-2 relative ">
        <h3 className="text-gray-600">{title}</h3>
        <div
          className="w-full  h-full flex justify-start items-center flex-nowrap
         pt-1 pb-4 gap-2 overflow-x-scroll sm:overflow-x-hidden "
          ref={content}
        >
          {products.map((product, i) => (
            <Card
              product={product}
              setIsShow={setIsShow}
              setImage={setImage}
              key={i}
            />
          ))}
        </div>
        <div
        
          className={`absolute text-5xl text-gray-400 
           bg-white w-[50px] h-[50px] border rounded-full hidden sm:flex  justify-center items-center top-1/2 left-[50px]
            -translate-x-1/2 -translate-y-1/2 transition-opacity delay-300  hover:cursor-pointer 
            hover:border-blue-500 ${scrollLeft === 0 ? "opacity-30" : ""} `}
        >
          <FaAngleLeft onClick={handleLeft} />
        </div>
        <div
         
          className={`absolute text-5xl text-gray-400 
           bg-white w-[50px] h-[50px] border rounded-full hidden sm:flex justify-center items-center top-1/2 right-[50px]
            translate-x-1/2 -translate-y-1/2 transition-opacity delay-300  hover:cursor-pointer 
            hover:border-blue-500 ${
              scrollLeft === maxScroll ? "opacity-30" : ""
            } `}
        >
          <FaAngleRight onClick={handleRight} />
        </div>
      </div>

      {isShow && <Loupe image={image} setIsShow={setIsShow} /> }
    </>
  )
}
