import { API_URL, NOIMAGE } from "../config/index.js"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  getCurrencySymbol,
  getOffers,
  getPriceForShow,
  isNotEmpty,
} from "../utils/index"
import { useContext, useEffect, useState } from "react"
import Slider from "../components/Slider"
import ProductsContext from "../context/ProductsContext"
import Links from "../components/Links"
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { useCurrencyRate } from "../swr"
import { FaPencilAlt, FaTimes } from "react-icons/fa"
import AuthContext from "../context/AuthContext.js"
import HandleConfirm from "../components/HandleConfirm.js"
import { Helmet } from "react-helmet-async"
import { PiShoppingCartSimpleBold } from "react-icons/pi"
import ClientPrice from "../components/ClientPrice.js"

export default function Product() {
  const navigate = useNavigate()

  // populate  !!!
  const product = useLoaderData()

  const currencyRate = useCurrencyRate()

  const { currencyShop, cart, setCart } = useContext(ProductsContext)
  const {
    user: { isAdmin },
  } = useContext(AuthContext)
  const [qntAmount, setQntAmount] = useState({ qnt: "", amount: "" })

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  // Здесь values то же самое что и product.optionValues но вместо barcode qnt
  const [values, setValues] = useState(
    JSON.parse(JSON.stringify(product.optionValues))
  )

  const [sliderValues, setSliderValues] = useState({
    isShow: false,
    idx: 0,
  })

  const [mainImageIdx, setMainImageIdx] = useState(0)

  const [cartBtnDisable, setCartBtnDisable] = useState(true)

  const crumbsString = product.catalogId.crumbs
    .map((item) => item.name)
    .join(" ")
    .toLowerCase()

  useEffect(() => {
    let totalQnt = 0
    let amount = 0
    const tableObj = values
    const deep = (optionValues) => {
      if (optionValues.hasOwnProperty("price")) {
        let qnt = parseInt(optionValues.qnt) || 0
        totalQnt += qnt
        amount += (parseFloat(optionValues.price) || 0) * qnt
        return
      } else {
        Object.keys(optionValues).forEach((item) => {
          deep(optionValues[item])
        })
      }
    }
    deep(tableObj)
    setCartBtnDisable(totalQnt ? false : true)

    totalQnt = totalQnt.toString()
    amount = amount.toFixed(2) + " " + getCurrencySymbol(product.currencyValue)
    setQntAmount({ qnt: totalQnt, amount })
  }, [values, product])

  const handleCartClick = () => {
    if (cartBtnDisable) return
    // const options = Object.keys(product.options)
    const activeOptionsArray = Object.keys(product.ownOptions).filter(
      (item) => product.ownOptions[item].length
    )

    const addCart = []
    const crumbs = []
    const deep = (optionValues) => {
      if (optionValues.hasOwnProperty("price")) {
        if (optionValues.qnt) {
          const options = Object.assign(
            {},
            ...activeOptionsArray.map((item, i) => ({ [item]: crumbs[i] }))
          )
          addCart.push({
            name: product.name,
            options,
            qnt: optionValues.qnt,
            price: optionValues.price,
            currencyValue: product.currencyValue,
          })
        }
      } else {
        Object.keys(optionValues).forEach((item) => {
          crumbs.push(item)
          deep(optionValues[item])
          crumbs.pop()
        })
      }
    }
    deep(values)
    setCart([...cart, ...addCart])
    resetQnt()
  }

  const resetQnt = () => {
    const copyValues = JSON.parse(JSON.stringify(values))
    const deep = (optionValues) => {
      if (optionValues.hasOwnProperty("price")) {
        optionValues.qnt = ""
        return
      } else {
        Object.keys(optionValues).forEach((item) => {
          deep(optionValues[item])
        })
      }
    }
    deep(copyValues)
    setValues(copyValues)
  }

  const handleDelete = async (rez) => {
    if (rez) {
      const res = await fetch(`/api/products/${product._id}`, {
        method: "DELETE",
      })
      const data = await res.json()
      if (!res.ok) {
        toast.error(data.message)
      } else {
        navigate(-1)
      }
    }

    setShowDeleteModal(false)
  }

  return (
    <>
      {!!currencyRate && !!currencyShop && (
        <Helmet>
          <title>{`${product.name} | Кармен. Купить оптом, опт, продажа оптом, мелким оптом, Барабащово`}</title>
          <meta
            name="description"
            content={`${product.description} ${product.name}. Бренд ${product.brandId.name}.Оптом`}
          />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              name: product.name,
              image: product.images.map((item) => `${API_URL}${item.lg}`),
              description: product.description,
              brand: {
                "@type": "Brand",
                name: product.brandId.name,
              },
              offers: getOffers({ product, currencyRate }),
            })}
          </script>
        </Helmet>
      )}
      <div className="px-2">
        <Links home={true} back={true}>
          <div className="flex justify-between items-center">
            <div className="flex items-center text=base flex-wrap text-base">
              {product.catalogId.crumbs.map((item, i, arr) => (
                <div className="flex items-center text-sb" key={i}>
                  <Link to={`/catalog/${item.slug}/${item._id}`}>
                    <p className=" cursor-pointer hover:text-blue-800">
                      {item.name}
                    </p>
                  </Link>
                  {i < arr.length - 1 ? <div className="px-1">➔</div> : null}
                </div>
              ))}
            </div>

            {isAdmin && (
              <div className="hidden md:flex items-center justify-center gap-3">
                <FaPencilAlt
                  className="text-sb text-2xl cursor-pointer"
                  title="Редактировать"
                  onClick={() => navigate(`/edit_product/${product._id}`)}
                />
                <FaTimes
                  className="text-red-500 text-3xl cursor-pointer"
                  title="Удалить"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                />
              </div>
            )}
          </div>
        </Links>
        <ToastContainer />

        <div className="w-full pt-4 text-lg">
          <div className=" mb-2 p-1 text-xl lg:text-2xl bg-amber-50 rounded-lg">
            <h1 className="px-2  italic  ">{product.name}</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-[1fr_2fr] gap-4">
            <div>
              <div
                className="flex items-center justify-center border border-gray-500
                 rounded-lg p-1 aspect-[3/4] cursor-zoom-in active:scale-[0.995]"
              >
                <img
                  className="max-w-full max-h-full"
                  src={
                    product.images.length
                      ? `${product.images[mainImageIdx].lg}`
                      : `${NOIMAGE}`
                  }
                  alt={product ? product.name : ""}
                  onClick={
                    product.images.length
                      ? () =>
                          setSliderValues({ isShow: true, idx: mainImageIdx })
                      : null
                  }
                />
              </div>
              <div
                className="py-3 grid grid-cols-[repeat(auto-fill,minmax(60px,1fr))] 
                  max-w-full gap-3 justify-items-center"
              >
                {!!product.images.length &&
                  product.images.map((item, i) => (
                    <div
                      key={i}
                      className={`flex items-center justify-center w-[60px] h-[80px]
                       border ${
                         i === mainImageIdx
                           ? "border-red-500"
                           : "border-gray-600"
                       }
                         p-1 cursor-pointer active:scale-[0.98]`}
                    >
                      <img
                        className="max-w-full max-h-full"
                        src={`${item.sm}`}
                        alt=""
                        onClick={() => setMainImageIdx(i)}
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="flex my-1 p-2 justify-between items-center text-xl lg:text-2xl bg-gray-100 rounded-lg">
                <button
                  className=" text-2xl text-gray-50 bg-sb px-4 py-1 rounded-md cursor-pointer hover:bg-sb-dark 
                    flex items-center gap-2  disabled:opacity-50 disabled:cursor-auto "
                  disabled={cartBtnDisable}
                  onClick={handleCartClick}
                >
                  <PiShoppingCartSimpleBold />
                  <p className="hidden sm:block text-xl ">в корзину</p>
                </button>

                <h2 className="relative min-w-[100px]">
                  {!!currencyRate && (
                    <div className="flex justify-end gap-x-1 text-2xl xl:text-3xl font-bold text-gray-700 pr-1 whitespace-nowrap">
                      {getPriceForShow({
                        currencyRate,
                        currencyShop,
                        product,
                      }) +
                        " " +
                        getCurrencySymbol(currencyShop)}
                    </div>
                  )}
                  <div className="absolute -top-3 right-1 text-sm text-orange-700">
                    <p>оптовая цена</p>
                  </div>
                </h2>
              </div>
              <div>
                {isNotEmpty(values) && (
                  <>
                    <ClientPrice
                      product={product}
                      setValues={setValues}
                      values={values}
                    />
                  </>
                )}
              </div>
              <div className="flex items-center justify-between  italic text-lg bg-teal-50 p-0.5">
                <p>Выбрано: {qntAmount.qnt}</p>
                <p>Сумма: {qntAmount.amount}</p>
              </div>
            </div>
          </div>
          {product ? (
            <div className="italic text-gray-500 mt-2 py-1 px-2 border border-gray-300 rounded-md">
              <h2 className="font-bold">Описание</h2>
              <h3 className="indent-6">
                {product.description}. Бренд {product.brandId.name}
              </h3>
              <h3 className="indent-6 text-sm text-gray-400">
                Купить оптом {crumbsString} {product.name}. Доставка с
                Барабашово или Новая Почта
              </h3>
            </div>
          ) : null}

          {sliderValues.isShow && (
            <Slider
              setSliderValues={setSliderValues}
              sliderValues={sliderValues}
              images={product.images}
              setMainImageIdx={setMainImageIdx}
            />
          )}
          {showDeleteModal && (
            <HandleConfirm
              title1={"Удалить товар"}
              title2={`${product.name}?`}
              handle={handleDelete}
            />
          )}
        </div>
      </div>
    </>
  )
}
