import React from "react"
import { stringToPrice } from "../utils"
import { FaShareAlt, FaTimes } from "react-icons/fa"

// optionValues ссылка на последний кусочек объекта местный optionValues
// optionValuesOrigin ссылка на оргинальный optionValues (values.optionValues)
// saveValues функция сохранения объекта optionValues глобальный
export default function AdminPriceBlock({
  optionValues,
  saveValues,
  crumbs,
  optionValuesOrigin,
}) {
  const changeHandler = (e) => {
    e.preventDefault()
    const { value, name } = e.target
    optionValues[name] = value
    saveValues()
  }

  const handleBlur = (e) => {
    let { value } = e.target
    value = stringToPrice(value)
    optionValues.price = value
    saveValues()
  }

  const shareHandler = () => {
      let values = optionValuesOrigin
      const deep = (values) => {
        if (values.hasOwnProperty("price")) {
          values.price = optionValues.price
          return
        }
        Object.keys(values).forEach((key) => {
          deep(values[key])
        })
      }
      deep(values)
      saveValues()
  }
  const shareResetHandler = () => {
    let values = optionValuesOrigin
    const deep = (values) => {
      if (values.hasOwnProperty('price')) {
        values.price = ''
        return
      }
      Object.keys(values).forEach(key => {
        deep(values[key])
      })
    }
    deep(values)
    saveValues()
  }

  const shareCloseHandler = () => { 
    let arr = crumbs.slice(0, -1)
    let values = arr.reduce((acc, item) => acc[item], optionValuesOrigin)
    Object.keys(values).forEach((key) => (values[key].price = optionValues.price))
    saveValues()
  }

  const shareCloseResetHandler = () => {
    let arr = crumbs.slice(0, -1)
    let values = arr.reduce((acc, item) => acc[item], optionValuesOrigin)
    Object.keys(values).forEach((key) => (values[key].price = ""))
    saveValues()
  }


  return (
    <div className="flex gap-x-3">
      <div>
        {!!crumbs.length && (
          <div className="flex items-center gap-3 text-base">
            <div className="flex items-center justify-center gap-3 border border-gray-400 p-1 rounded-md">
              <FaShareAlt
                className=" text-orange-400 cursor-pointer"
                title="Скопировать на все поля"
                onClick={shareHandler}
              />
              <FaTimes
                className="text-red-500 text-base cursor-pointer"
                title="Удалить со всех полей"
                onClick={shareResetHandler}
              />
            </div>
            <div className="flex items-center justify-center gap-3 border border-gray-400 p-1 rounded-md">
              <FaShareAlt
                className=" text-green-700 cursor-pointer"
                title="Скопировать в пределах опции"
                onClick={shareCloseHandler}
              />
              <FaTimes
                className="text-red-500 text-base cursor-pointer"
                title="Удалить в пределах опции"
                onClick={shareCloseResetHandler}
              />
            </div>
          </div>
        ) }
      </div>
      <input
        className="text-center text-lg/6 w-24"
        type="text"
        id="price"
        name="price"
        placeholder="Цена"
        value={optionValues.price}
        onChange={changeHandler}
        onBlur={handleBlur}
      />
      <input
        className="text-center text-lg/6 w-40"
        type="text"
        id="barcode"
        name="barcode"
        maxLength={13}
        placeholder="Штрихкод"
        value={optionValues.barcode}
        onChange={changeHandler}
      />
    </div>
  )
}
