import { useEffect, useState } from "react"

import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from "react-toastify"
import { FaPlusCircle, FaTimes } from "react-icons/fa"

export default function Options({ values, setValues }) {
  const initialOptions = Object.assign(
    {},
    ...Object.keys(values.options).map((option) => ({ [option]: "" }))
  )

  const [inputValue, setInputValue] = useState({
    option: "",
    ...initialOptions,
  })

  useEffect(() => {
    const inputOptions = Object.assign(
      {},
      ...Object.keys(values.options).map((option) => ({ [option]: "" }))
    )
    setInputValue({ option: "", ...inputOptions })
  }, [values.options])

  // example options.color.values.red.price

  const handleOptionName = async (e) => {
    e.preventDefault()

    const { value } = e.target
    const ucValue = value.charAt(0).toUpperCase() + value.slice(1)
    setInputValue({ ...inputValue, option: ucValue })
  }
  const handleName = async (e) => {
    e.preventDefault()

    const { value } = e.target

    setValues((prev) => ({ ...prev, name: value }))
  }

  const handleInputValue = async (e) => {
    e.preventDefault()

    const { name, value } = e.target

    setInputValue((prev) => ({ ...prev, [name]: value }))
  }

  const addOption = () => {
    if (Object.keys(values.options).length === 4) {
      toast.warning("Не рекомендуется делать кол-во опций больше 4")
      return
    }

    if (!inputValue.option.trim()) {
      toast.error("Заполните поле опция")
      return
    }
    const keys = Object.keys(inputValue)
    const isRepeat = keys.find((item) => item === inputValue.option)
    if (!isRepeat) {
      setValues((prev) => ({
        ...prev,
        options: { ...values.options, [inputValue.option]: [] },
      }))
      setInputValue((prev) => ({ ...prev, option: "" }))
    } else {
      toast.error("Такая опция уже существует или зарезервирована")
    }
  }

  const addOptionValue = ({ name, value }) => {
    if (!value.trim()) {
      toast.error("Заполните поле значение опции")
      return
    }

    setValues((prev) => ({
      ...prev,
      options: {
        ...values.options,
        [name]: [...values.options[name], value].sort(),
      },
    }))
    setInputValue((prev) => ({ ...prev, [name]: "" }))
  }

  const handlePress = ({ e, cb }) => {
    if (e.key === "Enter") {
      e.preventDefault()
      cb()
    }
  }

  const deleteOptionsValue = (item, i) => {
    setValues((prev) => ({
      ...prev,
      options: {
        ...values.options,
        [item]: values.options[item].filter((item, j) => i !== j),
      },
    }))
  }
  const deleteOption = (name) => {
    const { [name]: deletedField, ...newInputValue } = inputValue
    const { [name]: deletedField2, ...newOptions } = values.options
    setInputValue(newInputValue)
    setValues((prev) => ({ ...prev, options: newOptions }))
  }

  const resetOptions = () => {
    setValues({
      _id: null,
      name: "",
      options: {},
    })
  }

  return (
    <div>
      <ToastContainer />
      <div className=" flex items-center justify-between gap-x-[10px] mb-2">
        <input
          type="text"
          className="my-1 h-8 text-xl px-1 flex-1"
          placeholder="Название опций"
          value={values.name}
          onChange={handleName}
        />
        <FaTimes
          className="text-3xl text-red-500 hover:cursor-pointer"
          title="Новые опции"
          onClick={resetOptions}
        />
      </div>

      <div>
        <div className=" flex items-center justify-between gap-x-[10px] ">
          <input
            className="my-1 h-8 text-xl px-1 flex-1"
            type="text"
            name="option"
            placeholder="Название опции"
            value={inputValue.option}
            onChange={handleOptionName}
            onKeyDown={(e) => handlePress({ e, cb: addOption })}
          />
          <FaPlusCircle
            className="text-3xl text-emerald-700 hover:cursor-pointer"
            onClick={addOption}
            title="Добавить опцию"
          />
        </div>
      </div>

      {!!Object.keys(values.options).length
        && Object.keys(values.options).map((item, i) => (
            <div key={i}>
              <div>
                <label
                  htmlFor={item}
                  className=" px-1 flex items-center text-lg gap-x-3"
                >
                  {item}
                  <FaTimes
                    className="text-xl text-red-500 hover:cursor-pointer"
                    title="Удалить опцию"
                    onClick={() => deleteOption(item)}
                  />
                </label>
                <div className="group flex items-center justify-between gap-x-[10px] relative w-full">
                  <input
                    className="my-1 h-8 text-xl px-1 flex-1"
                    type="text"
                    name={item}
                    value={inputValue[item] || ""}
                    placeholder="Значение опции"
                    onChange={handleInputValue}
                    onKeyDown={(e) =>
                      handlePress({
                        e,
                        cb: () =>
                          addOptionValue({
                            name: item,
                            value: inputValue[item],
                          }),
                      })
                    }
                  />
                  <FaPlusCircle
                    className="text-3xl text-emerald-700 hover:cursor-pointer"
                    onClick={() =>
                      addOptionValue({
                        name: item,
                        value: inputValue[item],
                      })
                    }
                    title="Добавить значение опции"
                  />
                  <>
                    {!!values.options[item].length && (
                      <div className="group-hover:block hidden absolute top-full left-0 z-50 pt-1">
                        <div
                          className="flex bg-gray-50 min-w-full max-h-[400px] overflow-auto 
                        border border-gray-700  rounded-md p-3 shadow-md flex-wrap gap-3 "
                        >
                          {values.options[item].map((value, i) => (
                            <div
                              key={i}
                              className="flex items-center justify-center gap-4 border border-green-800
                                 rounded-md  px-2 py-1 text-xl"
                            >
                              <div>{value}</div>
                              <FaTimes
                                className="text-xl text-red-500 hover:cursor-pointer"
                                title="Удалить значение опции"
                                onClick={() => deleteOptionsValue(item, i)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          ))
        }
    </div>
  )
}
