import useSWR from "swr"



const fetcher = async (url) => {
  const res = await fetch(url)
  const data = await res.json()

  if (!res.ok) {
    const error = new Error()
    error.message = data.message
    error.status = res.status
    throw error
  }
  return data
}





export const useShowcase = () => {
  const { data } = useSWR(`/api/products/showcase`, fetcher)
  return data ? data.showcaseProducts : null
}

export const useCurrencyRate = () => {
  const { data } = useSWR(`/api/currencyrate`, fetcher)
  return data ? data.currencyRate : null
}

export const useCatalogs = () => {
  const { data } = useSWR(`/api/catalogs`, fetcher)
  return data ? data.catalogs : null
}

export const useCatalogsId = (id) => {
  const { data } = useSWR(`/api/catalogs/${id}`, fetcher)
  return data ? data.catalog : null
}

export const useProductsByCatalogId = ({ id, brandId,isForsed }) => {
  const { data } = useSWR(`/api/products/catalog?catalogId=${id}&brandId=${brandId}&isForsed=${isForsed}`, fetcher )
  return data ? data.products : null
}

export const useQntByCatalogId=({id,brandId})=> {
  const { data }=useSWR(id?`/api/catalogs/products/qnt?id=${id}&brandId=${brandId}`:null,fetcher)
  return data?data.qnt:0
}

export const useProductById = (id) => {
  const { data } = useSWR(`/api/products/product/${id}`, fetcher)
  return data ? data.product : null
}

export const useInformation = () => {
  const { data } = useSWR(`/api/information`, fetcher)
  return data ? data.information : null
}

export const useUsersList = () => {
  const { data } = useSWR(`/api/user`,fetcher)  
  return data ? data.users : null
}
export const useOrder = () => {
  const { data } = useSWR(`/api/order`,fetcher)  
  return data
    ? data.orders.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    : null
}

export const useOrderById = ({ id}) => {
  const { data } = useSWR(`/api/order/${id}`,fetcher)  
  return data ? data.order : null
}
export const useOrderUserById = ({ id}) => {
  const { data } = useSWR(`/api/order/user/${id}`,fetcher)  
  return data
    ? data.orders.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    : null
}

export const useGallery = () => {
  const { data } = useSWR(`/api/gallery`, fetcher)
  return data ? data.cards : null
}
export const useOptions = () => {
  const { data } = useSWR(`/api/options`, fetcher)
  return data ? data.options.sort((a, b) => (a.name > b.name ? 1 : -1)) : null
}
export const useBrands = () => {
  const { data } = useSWR(`/api/brands`, fetcher)
  return data ? data.brands.sort((a, b) => (a.name > b.name ? 1 : -1)) : null
}

export const useFilteredCatalogs = (id) => {
  const { data } = useSWR(`/api/catalogs/brand/${id}`, fetcher)
  return data ? data.catalogs.sort((a, b) => (a.name > b.name ? 1 : -1)) : null
}

export const useOptionsById = (id) => {
  const { data } = useSWR(
    `/api/options/${id}`,
    fetcher
  )
  return data ? data.options.options : null
}
