import React from "react"
import AdminPriceBlock from "./AdminPriceBlock"

export default function AdminOptionsPrice({
  optionValues,
  optionValuesOrigin,
  level,
  saveValues,
  crumbs,
}) {
  if (!level && optionValues.hasOwnProperty("price")) {
    return (
      <div className="flex  justify-end py-1">
        <AdminPriceBlock
          crumbs={crumbs}
          optionValues={optionValues}
          saveValues={saveValues}
        />
      </div>
    )
  } else {
    return (
      <div>
        {Object.keys(optionValues).map((option) => (
          <div key={`${level}${option}${crumbs.join("")}`}>  
            {optionValues[option].hasOwnProperty("price") ? (
              <div className="flex justify-between items-center option pr-2 py-1 overflow-x-auto">
                <div
                  style={{ paddingLeft: (level+1) * 20 + "px" }}
                 
                >
                  {option}
                </div>
                <AdminPriceBlock
                   optionValuesOrigin={optionValuesOrigin}
                  crumbs={[...crumbs,option]}
                  optionValues={optionValues[option]}
                  saveValues={saveValues}
                />
              </div>
            ) : (
              <>
                <div
                  style={{ paddingLeft: (level+1) * 20 + "px" }}
                  className=" option py-1 flex items-center"
                >
                  {option}
                </div>

                  <AdminOptionsPrice
                    optionValuesOrigin={optionValuesOrigin}
                  level={level + 1}
                  crumbs={[...crumbs, option]}
                  optionValues={optionValues[option]}
                  saveValues={saveValues}
                />
              </>
            )}
          </div>
        ))}
      </div>
    )
  }
}
