import { useOptionsById } from "../swr"

export default function SelectOptions({ values, setValues }) {
  const optionsItem = useOptionsById(values.optionsId)

  const handleCheckbox = ({ name, option, checked }) => {
    if (checked) {
      // add option to values.options[name]
      setValues((prev) => ({
        ...prev,
        ownOptions: {
          ...values.ownOptions,
          [name]: [...values.ownOptions[name], option],
        },
      }))
    } else {
      setValues((prev) => ({
        ...prev,
        ownOptions: {
          ...values.ownOptions,
          [name]: values.ownOptions[name].filter((value) => value !== option),
        },
      }))
    }
  }

  return (
    <div className="py-3">
      {!!optionsItem
        && Object.keys(optionsItem).map((item, i) => (
            <div
              className="flex flex-wrap text-lg py-2 gap-3 items-center"
              key={i}
            >
              <p className="italic bg-[#F5CBA7] rounded-md py-1 px-3">
                {item}:
              </p>
              {optionsItem[item].sort().map((optionValue, i) => (
                <div key={i}>
                  <input
                    className="peer appearance-none"
                    type="checkbox"
                    id={`${item}${optionValue}`}
                    name={item}
                    value={optionValue}
                    onChange={(e) =>
                      handleCheckbox({
                        name: item,
                        option: optionValue,
                        checked: e.target.checked,
                      })
                    }
                    checked={
                      values.ownOptions[item]
                        ? values.ownOptions[item].includes(optionValue)
                        : false
                    }
                  />
                  <label
                    className="border border-emerald-600 py-2 px-4 rounded-md hover:cursor-pointer
                       peer-checked:bg-emerald-700 peer-checked:text-gray-50"
                    htmlFor={`${item}${optionValue}`}
                    tabIndex={0}
                  >
                    {optionValue}
                  </label>
                </div>
              ))}
            </div>
          ))
        }
    </div>
  )
}
