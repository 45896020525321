import {  NOIMAGE } from "../config/index"
import {
  getCurrencySymbol,
  getPriceForShow,
  getShortDescription,
} from "../utils/index"
import { useCallback, useContext, useEffect, useState } from "react"
import ProductsContext from "../context/ProductsContext"

import Loupe from "./Loupe"
import { useNavigate } from "react-router-dom"
import { useCurrencyRate, useProductsByCatalogId } from "../swr"
import Pagination from "./Pagination"


export default function ProductsList({  isShowAsList,id,brand,isShowProducts }) {
  const [innerWidth, setInnerWidth] = useState(0)
  const navigate = useNavigate()
  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])

  const currencyRate = useCurrencyRate()

  const { currencyShop } = useContext(ProductsContext)
  const [isShow, setIsShow] = useState(false)
   const [productsOnPage, setProductsOnPage] = useState([])  
  const [image, setImage] = useState("")

  const memSetProductOnPage = useCallback(
    (products) => setProductsOnPage(products),
    []
  )

    const products = useProductsByCatalogId({
      id,
      brandId: brand._id ? brand._id : "",      
      isForsed: isShowProducts ? isShowProducts : "",
    })

    const productList = products
      ? products.sort((a, b) => (a.name > b.name ? 1 : -1))
      : []

  return (
    <>
      {isShowAsList ? (
        <div className="px-1">
          <table className="w-full border-collapse table-fixed text-base border border-gray-500">
            <thead className="border border-gray-500">
              <tr>
                <th className="w-[70px]">&nbsp;</th>
                <th className="w-[180px]">Название</th>

                <th className="w-auto hidden lg:table-cell">Описание</th>
                <th className="w-[100px]">Цена</th>
              </tr>
            </thead>
            <tbody className="border border-gray-500">
              {productsOnPage.map((item, i) => (
                <tr
                  className="border border-gray-500 hover:border-x-blue-500 hover:border-x
                     hover:cursor-pointer hover:bg-gray-50"
                  onClick={() => navigate(`/product/${item.slug}/${item._id}`)}
                  key={i}
                >
                  <td className=" text-center align-middle leading-4 border border-gray-500 ">
                    <div
                      className=" inline-flex justify-center items-center p-0.5
                         h-[85px] w-[65px]"
                    >
                      <img
                        className="max-w-full max-h-full cursor-zoom-in active:scale-95"
                        src={
                          item.images.length
                            ? `${item.images[0].sm}`
                            : `${NOIMAGE}`
                        }
                        alt={item.name}
                        onClick={
                          item.images.length
                            ? (e) => {
                                e.stopPropagation()
                                setIsShow(true)
                                setImage(item.images[0].lg)
                              }
                            : (e) => e.stopPropagation()
                        }
                      />
                    </div>
                  </td>
                  <td className="text-center border border-gray-500 px-1">
                    <h3>{item.name}</h3>
                  </td>

                  <td className="text-center border border-gray-500 px-1 hidden lg:table-cell">
                    {getShortDescription(item.description, innerWidth / 15)}
                  </td>
                  <td className="text-center text-xl border border-gray-500 px-1">
                    {currencyRate ? (
                      <div>
                        {getPriceForShow({
                          currencyRate,
                          currencyShop,
                          product: item,
                        })}{" "}
                        {getCurrencySymbol(currencyShop)}
                      </div>
                    ) : (
                      <div> </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
        </div>
      ) : (
        <div className="grid gap-1 grid-cols-2 auto-rows-auto sm_1:auto-rows-[520px]   sm_1:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] sm_1:gap-5 justify-items-center">
          {productsOnPage.map((item, i) => (
            <div
              // className="flex flex-col gap-1 pt-1 items-center border border-gray-500 shadow-md w-[300px] h-[470px]
              //  overflow-auto hover:cursor-pointer hover:border-blue-500"
              className="grid grid-cols-[minmax(0,300px)] grid-rows-[minmax(0,1fr),auto,40px] sm_1:grid-rows-[minmax(0,1fr),minmax(50px,auto),50px]  border border-gray-500 shadow-md
               hover:cursor-pointer hover:border-blue-500"
              onClick={() => navigate(`/product/${item.slug}/${item._id}`)}
              key={i}
            >
              <div className="p-1 flex items-center justify-center ">
                <img
                  className="max-w-full max-h-full  hover:cursor-zoom-in active:scale-95"
                  src={
                    item.images.length ? `${item.images[0].md}` : `${NOIMAGE}`
                  }
                  alt={item.name}
                  onClick={
                    item.images.length
                      ? (e) => {
                          e.stopPropagation()
                          setIsShow(true)
                          setImage(item.images[0].lg)
                        }
                      : (e) => e.stopPropagation()
                  }
                />
              </div>
              <h3 className="flex  items-center justify-center  border border-y-gray-500 text-gray-800  text-lg/5 sm_1:text-xl/6 p-1 indent-1 hyphens-auto">
                {item.name}
              </h3>

              <div className="  flex justify-center items-center bg-gray-200 text-xl sm_1:text-2xl ">
                {currencyRate ? (
                  <p>
                    {getPriceForShow({
                      currencyRate,
                      currencyShop,
                      product: item,
                    })}{" "}
                    {getCurrencySymbol(currencyShop)}
                  </p>
                ) : (
                  <p> </p>
                )}
              </div>
            </div>
          ))}

          {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
        </div>
      )}
      {!!productList.length && (
        <Pagination
          productList={productList}
          setProductsOnPage={memSetProductOnPage}
          id={id}
        />
      )}
    </>
  )
}
