import React, { useEffect, useState } from 'react'
import { localStorageService } from '../utils'

export default function Pagination({  id,productList,setProductsOnPage }) {  
  
   const [currentPage, setCurrentPage] = useState(() => {
     const data = localStorageService.get("catalog")
     return data && data[id] ? data[id] : 1
   })

  const productsPerPage = 12
  
   const idxOfLastProduct = currentPage * productsPerPage
   const idxOfFirstProduct = idxOfLastProduct - productsPerPage
 

   const numbers = []
   for (let i = 1; i <= Math.ceil(productList.length / productsPerPage); i++) {
     numbers.push(i)
  }

  useEffect(() => {      
      setProductsOnPage(productList.slice(idxOfFirstProduct, idxOfLastProduct))
  },[productList,idxOfFirstProduct,idxOfLastProduct,setProductsOnPage])
  
   useEffect(() => {
     let data = localStorageService.get("catalog")
     // предохранитель если окажется что номер в localStorage больше чем есть страниц
     if (data && data[id] && productList.length && numbers.length < data[id]) {
       data = localStorageService.delete("catalog", id)
     }
     setCurrentPage(data && data[id] ? data[id] : 1)
   }, [id, productList.length, numbers.length])

  const handleClick = (item) => {
    if (!id) return
   localStorageService.update('catalog',{[id]:item})
    setCurrentPage(item)
  }
  
  return (
    <>
     {numbers.length>1 ? (
     <div className='pt-2 flex justify-center items-center gap-2 flex-wrap'>
      {numbers.map(item => (
        <div key={item} className={`text-2xl  flex justify-center items-center
         w-10 h-10 border border-blue-500 rounded-full  ${currentPage === item ?
            'bg-sb text-gray-100' : 'bg-inherit text-inherit cursor-pointer hover:bg-gray-50'}`} onClick={()=>handleClick(item)}>
         <p>{item}</p> 
        </div>
      ))}
    </div>
    ):null}
    </>
   
   
  )
}
