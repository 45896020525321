import { useEffect, useState } from "react"
import { useCatalogs } from "../swr"
import { API_URL } from "../config"
import slugify from "slugify"

export function getCurrencySymbol(currencyShop) {
  switch (currencyShop) {
    case "UAH":
      return "₴"

    case "EUR":
      return "€"

    case "USD":
      return "$"

    default:
      return "₴"
  }
}

export const idToString = (id) => {
  return id === null ? "" : id.toString()
}

export const isNotEmpty = (obj) => {
  return !!Object.keys(obj).length
}

export function getPriceForShow({ currencyShop, currencyRate, product }) {
  const { min, max } = getStringPrice(product)
  const showMin = (
    (currencyRate[product.currencyValue] * +min) /
    currencyRate[currencyShop]
  ).toFixed(2)
  const showMax = (
    (currencyRate[product.currencyValue] * +max) /
    currencyRate[currencyShop]
  ).toFixed(2)
  const string = min === max ? showMin : `${showMin} - ${showMax}`
  return string
}

export const getCatTree = (cat, catArray) => {
  var result = [cat.name]
  const findParent = (cat) => {
    const parent = catArray.find((elem) => elem._id === cat.parentId)
    if (parent) {
      result.push(parent.name)
      findParent(parent)
    }
    return
  }
  findParent(cat)

  return result.reverse().join(" ➔  ") 
}



export const getShortDescription = (description, length) => {
  return description.length > length
    ? `${description.slice(0, length)}...`
    : description
}

export const stringToPrice = (string) => {
  const priceNum =
    parseFloat(string.replace(/[^\d.,]+/g, "").replace(",", ".")) || 0

  return priceNum === 0 ? "" : priceNum.toFixed(2)
}

export const getQntInCart = (cart) =>
  cart.reduce((acc, item) => acc + +item.qnt, 0)
export const getTotalInCart = (cart) =>
  cart.reduce(
    (acc, item) => ({
      ...acc,
      [item.currencyValue]: acc[item.currencyValue] + item.qnt * item.price,
    }),
    {
      UAH: 0,
      USD: 0,
      EUR: 0,
    }
  )

export const getTotalAmount = (cart) => {
  const totalObj = getTotalInCart(cart)
  let strArr = []
  for (let key in totalObj) {
    if (totalObj[key]) {
      strArr.push(`${totalObj[key].toFixed(2)}${getCurrencySymbol(key)}`)
    }
  }
  return strArr.join(" + ") || "0"
}


export const formatingPhone = (number) => {
  const digit = number.replace(/\D/g, "").slice(2)

  if (digit.length < 4) {
    return `+38 (${digit}`
  } else {
    if (digit.length < 7) {
      return `+38 (${digit.slice(0, 3)}) ${digit.slice(3, 6)}`
    } else {
      if (digit.length < 9) {
        return `+38 (${digit.slice(0, 3)}) ${digit.slice(3, 6)}-${digit.slice(
          6,
          8
        )}`
      } else {
        if (digit.length >= 9) {
          return `+38 (${digit.slice(0, 3)}) ${digit.slice(3, 6)}-${digit.slice(
            6,
            8
          )}-${digit.slice(8, 10)}`
        }
      }
    }
  }
}

export const formatingDate = (value) => {
  const date = new Date(value)
  const day = date.getDate().toString().padStart(2, "0")
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const year = date.getFullYear().toString().padStart(2, "0")
  return `${day}-${month}-${year}`
}

export const createPriceObject = ({ ownOptions, optionValues }) => {
  // убираем поля пустышки
  const fillingOwnOptions = Object.assign(
    {},
    ...Object.keys(ownOptions)
      .filter((item) => ownOptions[item].length)
      .map((item) => ({ [item]: ownOptions[item].sort() }))
  )

  let rez = { price: "", barcode: "", qnt: "" }

  Object.keys(fillingOwnOptions)
    .reverse()
    .forEach((option) => {
      rez = Object.assign(
        {},
        ...fillingOwnOptions[option].map((value) => ({
          [value]: JSON.parse(JSON.stringify(rez)),
        }))
      )
    })

  const deep = (newOptions, oldOptions) => {
    if (
      newOptions.hasOwnProperty("price") &&
      oldOptions.hasOwnProperty("price")
    ) {
      newOptions.price = oldOptions.price
      newOptions.barcode = oldOptions.barcode
    } else {
      Object.keys(newOptions).forEach((item) => {
        if (!oldOptions.hasOwnProperty(item)) return

        deep(newOptions[item], oldOptions[item])
      })
    }
    return newOptions
  }

  return deep(rez, optionValues)
}

export const getStringPrice = (values) => {
  const pricesArray = []
  const deep = (options) => {
    if (options.hasOwnProperty("price")) {
      if (options.price) {
        pricesArray.push(options.price)
      }
      return
    } else {
      Object.keys(options).forEach((item) => {
        deep(options[item])
      })
    }
  }

  deep(values.optionValues)

  const min = pricesArray.sort((a, b) => a - b)[0] || ""
  const max = pricesArray.sort((a, b) => b - a)[0] || ""

  let string =
    min === max
      ? `${min.toString()} ${getCurrencySymbol(values.currencyValue)}`
      : `${min}...${max} ${getCurrencySymbol(values.currencyValue)}`

  return { string, min, max }
}

export const localStorageService = {
  get(key) {
    try {
      const data = localStorage.getItem(key)
      return data ? JSON.parse(data) : null
    } catch (error) {
      console.log(`Ошибка получения данных по ключу ${key}`, error)
      return null
    }
  },

  set(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(`Ошибка при сохранении по ключу ${key}`, error)
    }
  },
  update(key, newData) {
    try {
      const data = this.get(key) || {}
      this.set(key, { ...data, ...newData })
    } catch (error) {}
  },
  delete(key, id) {
    try {
      const data = this.get(key)
      if (data && data[id]) {
        delete data[id]
        this.set(key, data)
      }
      return data
    } catch (error) {
      console.log(`Ошибка при удалении данных по ключу ${key}`, error)
    }
  },
}

export const getOffers = ({ product, currencyRate }) => {
  const fields = Object.keys(product.ownOptions).filter(
    (option) => product.ownOptions[option].length
  )

  const crumbs = []
  const offers = []
  const deep = (values) => {
    if (values.hasOwnProperty("price")) {
      if (values.price) {
        offers.push({
          "@type": "Offer",
          url: `${API_URL}/product/${product.slug}/${product._id}`,
          availability: "https://schema.org/InStock",
          priceCurrency: product.currencyValue,
          price: values.price,
          sku: slugify(`${product.name} ${crumbs.join(" ")}`, {
            lower: true,
            strict: true,
          }),
          additionalProperty: fields.map((option, i) => ({
            "@type": "PropertyValue",
            name: option,
            value: crumbs[i],
          })),
        })
        if (product.currencyValue !== "UAH") {
          offers.push({
            "@type": "Offer",
            url: `${API_URL}/product/${product.slug}/${product._id}`,
            availability: "https://schema.org/InStock",
            priceCurrency: "UAH",
            price: (values.price * currencyRate[product.currencyValue]).toFixed(
              2
            ),
            sku: slugify(`${product.name} ${crumbs.join(" ")}`, {
              lower: true,
              strict: true,
            }),
            additionalProperty: fields.map((option, i) => ({
              "@type": "PropertyValue",
              name: option,
              value: crumbs[i],
            })),
          })
        }
      }
    } else {
      for (const item in values) {
        crumbs.push(item)
        deep(values[item])
        crumbs.pop()
      }
    }
  }
  deep(product.optionValues)
  return offers
}

export const ucFirst = (string) => {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}
