import { createContext, useState, useEffect } from "react"
import { localStorageService } from "../utils"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => localStorageService.get('user') || {}) 
 
  useEffect(() => {        
      localStorageService.set('user',user)   
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
