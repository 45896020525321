import { useContext, useEffect, useState } from "react"
import ProductsContext from "../context/ProductsContext"
import { getCurrencySymbol, getQntInCart, getTotalAmount } from "../utils/index"
import Links from "../components/Links"
import { useNavigate } from "react-router-dom"
import { FaTimes } from "react-icons/fa"
import { Helmet } from "react-helmet-async"
import { useCurrencyRate } from "../swr"
import { toast, ToastContainer } from "react-toastify"

export default function Cart() {
  const { cart, setCart } = useContext(ProductsContext)
  const [isDisable, setIsDisable] = useState(false)
  const navigate = useNavigate()

  const currencyRate = useCurrencyRate()

  useEffect(() => {
    setIsDisable(!getQntInCart(cart))
  }, [cart])
  // optionList собирает все возможные опции из корзины
  const optionList = cart.length
    ? cart.reduce((acc, item) => {
        const itemOptions = Object.keys(item.options)
        itemOptions.forEach((option) => {
          if (!acc.includes(option)) acc.push(option)
        })
        return acc
      }, [])
    : []

  const handleClick = () => {
    if (!currencyRate) {
      toast.error("Отсутствуют данные о курсе валют")
      return
    }
   
    const totalAmountInUAH = cart
      .reduce(
        (acc, item) =>
          acc + item.price * currencyRate[item.currencyValue] * item.qnt,
        0
      )
      .toFixed(2)
    if ( totalAmountInUAH > 1000) {
      navigate("/checkout")
    } else {
      toast.warning(
        "Минимальная сумма заказа 1000грн( см. раздел Информация). Для покупки в розницу свяжитесь, пожалуйста, с продавцом по телефону, указанному на сайте"
      )
    }
  }
 
  return (
    <div>
      <ToastContainer />
      <Helmet>
        <title>Корзина</title>
        <meta name="description" content="Корзина покупок в магазине Кармен" />
      </Helmet>

      <Links home={true} back={true}>
        <div className="flex justify-end ">
          <div className="p-0.5 border-2 border-sb rounded-lg">
            <button
              className="text-base text-gray-50 bg-sb px-4 py-1 rounded-lg
            disabled:opacity-50 disabled:cursor-auto hover:bg-sb-dark"
              onClick={handleClick}
              disabled={isDisable}
            >
              Далее
            </button>
          </div>
        </div>
      </Links>

      <div className="px-3 pt-4 text-lg overflow-auto" id="table">
        <table className=" table-fixed w-full border-collapse min-w-[768px] ">
          <thead className="text-center">
            <tr className="border border-gray-600">
              <td>Модель</td>
              {optionList.length
                ? optionList.map((item, i) => <td key={i}>{item}</td>)
                : null}
              <td className="w-[100px]">Цена</td>
              <td className="w-[100px]">Кол-во</td>
              <td className="w-[100px]">
                <FaTimes
                  className="inline text-red-500 text-xl cursor-pointer"
                  title="Очистить корзину"
                  onClick={() => setCart([])}
                />
              </td>
            </tr>
          </thead>
          <tbody className="text-center">
            {cart && cart.length ? (
              cart.map((item, i) => (
                <tr className="border border-gray-600" key={i}>
                  <td>{item.name}</td>
                  {optionList.length
                    ? optionList.map((option, i) => (
                        <td key={i}>{item.options[option]}</td>
                      ))
                    : null}
                  <td>
                    {item.price}&nbsp;{getCurrencySymbol(item.currencyValue)}
                  </td>
                  <td>{item.qnt}</td>
                  <td>
                    <FaTimes
                      className="inline text-red-500 text-xl cursor-pointer"
                      title="Удалить строку"
                      onClick={() =>
                        setCart(cart.filter((item, idx) => i !== idx))
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border border-gray-600">
                <td>&nbsp;</td>
              </tr>
            )}
            <tr className="border border-gray-600">
              <td colSpan={optionList.length + 4}>
                <div className="flex justify-between px-1 bg-gray-100">
                  <p>Всего товаров: {cart ? getQntInCart(cart) : ""}</p>
                  <p>Сумма заказа: {cart ? getTotalAmount(cart) : ""}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
