import { createContext, useEffect, useState } from "react"
import { localStorageService } from "../utils"

const ProductsContext = createContext()

export const ProductsProvider = ({ children }) => {
  const [currencyShop, setCurrencyShop] = useState("UAH")
 
const [isTabActive,setIsTabActive]=useState(!document.hidden)
  const [cart, setCart] = useState(() => localStorageService.get("cart") || []) 
  
  useEffect(() => {
    setCart(localStorageService.get('cart') || [])    
  },[isTabActive])  

  useEffect(() => {    
      localStorageService.set('cart', cart)  
  }, [cart])
  
  useEffect(() => {
    const handleChange = () => {
      setIsTabActive(!document.hidden)
    }
  document.addEventListener('visibilitychange',handleChange)
    return () => {
  document.removeEventListener('visibilitychange',handleChange)
}
},[])

  return (
    <ProductsContext.Provider
      value={{
        currencyShop,
        setCurrencyShop,
        cart,
        setCart,
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

export default ProductsContext
