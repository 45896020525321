import React, { useContext } from "react"

import { getCurrencySymbol, getPriceForShow } from "../utils"
import { useCurrencyRate } from "../swr"
import { useNavigate } from "react-router-dom"
import ProductsContext from "../context/ProductsContext"
import { NOIMAGE } from "../config"

export default function CardPopular({ product, setIsShow, setImage }) {
  const navigate = useNavigate()

  const { currencyShop } = useContext(ProductsContext)

  const currencyRate = useCurrencyRate()
  return (
    <div onClick={() => navigate(`/product/${product._id}`)}>
      <div
        className="flex flex-col items-center gap-1 text-xl/6  p-1 w-[300px] h-[450px]
       border border-gray-500 shadow-md hover:cursor-pointer hover:border-blue-500"
      >
        <div className="flex justify-center items-center w-[290px] h-[330px] border border-gray-500">
          <img
            className="max-w-full max-h-full hover:cursor-zoom-in"
            src={
              product.images.length ? `${product.images[0].md}` : `${NOIMAGE}`
            }
            alt=""
            onClick={(e) => {
              e.stopPropagation()
              setIsShow(true)
              setImage(product.images[0].lg)
            }}
          />
        </div>
        <div className="flex-1 text-lg/6 text-gray-700 w-full flex items-center justify-center ">
          <h3>{product.name}</h3>
        </div>
        <div className="w-full h-[35px] flex items-center justify-center bg-gray-200">
          {!!currencyRate && (
            <p>
              {getPriceForShow({
                currencyRate,
                currencyShop,
                product,
              })}{" "}
              {getCurrencySymbol(currencyShop)}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
